<template>
  <div class="rtc-container">
    <p v-if="isHostMode" class="label">{{ $t('Operation') }}</p>
    <div class="control-container">
      <div class="rtc-control-container">
        <el-button
          class="button"
          type="primary"
          size="small"
          :loading="roomStatus === 'entering'"
          :disabled="roomStatus === 'entered'"
          @click="handleEnterRoom">{{ $t('Enter Room') }}</el-button>
        <el-button
          class="button"
          type="primary"
          size="small"
          :loading="roomStatus === 'exiting'"
          @click="handleExit">{{ $t('Exit Room') }}</el-button>
      </div>
      <div class="rtc-control-container">
        <el-button
          v-if="isHostMode"
          class="button"
          type="primary"
          :loading="micStatus === 'starting'"
          :disabled="micStatus === 'started'"
          size="small" @click="handleStartLocalAudio">{{ $t('Start Local Audio') }}
        </el-button>
        <el-button
          v-if="isHostMode"
          class="button"
          type="primary"
          :loading="camStatus === 'starting'"
          :disabled="camStatus === 'started'"
          size="small" @click="handleStartLocalVideo">{{ $t('Start Local Video') }}
        </el-button>
        <el-button
          v-if="isHostMode"
          class="button"
          :loading="micStatus === 'stopping'"
          type="primary" size="small" @click="handleStopLocalAudio">{{ $t('Stop Local Audio') }}
        </el-button>
        <el-button
          v-if="isHostMode"
          class="button"
          :loading="camStatus === 'stopping'"
          type="primary" size="small" @click="handleStopLocalVideo">{{ $t('Stop Local Video') }}
        </el-button>
      </div>
      <div v-if="isHostMode" class="screen-share-control-container">
        <el-button
          class="button"
          type="primary"
          size="small"
          :loading="shareStatus === 'sharing'"
          :disabled="shareStatus === 'shared'"
          @click="handleStartScreenShare">{{ $t('Start Screen Share') }}</el-button>
        <el-button
          class="button"
          type="primary"
          size="small"
          :loading="shareStatus === 'stopping'"
          @click="handleStopScreenShare">{{ $t('Stop Screen Share') }}</el-button>
      </div>
    </div>

    <div class="info-container" :class="$isMobile && 'info-container-mobile'">
      <div class="log-container" ref="logContainer">
        <p class="log-label">Log:</p>
        <div v-for="(item, index) in logList" :key="index">
          <span class="log-state" v-if="item.type === 'success'">🟩 </span>
          <span class="log-state" v-if="item.type === 'failed'">🟥 </span>
          <span>{{ item.log }}</span>
        </div>
      </div>

      <div v-show="camStatus === 'started'" class="local-stream-container">
        <div id="local" class="local-stream-content"></div>
        <div class="local-stream-control">
          <div class="video-control control">
            <span v-if="!isMutedVideo" @click="muteVideo">
              <svg-icon icon-name="video" class="icon-class"></svg-icon>
            </span>
            <span v-if="isMutedVideo"  @click="unmuteVideo">
              <svg-icon icon-name="video-muted" class="icon-class"></svg-icon>
            </span>
          </div>
          <div class="audio-control control">
            <span v-if="!isMutedAudio" @click="muteAudio">
              <svg-icon icon-name="audio" class="icon-class"></svg-icon>
            </span>
            <span v-if="isMutedAudio" @click="unmuteAudio">
              <svg-icon icon-name="audio-muted" class="icon-class"></svg-icon>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="remote-container">
      <div
        v-for="(item) in remoteUsersViews"
        :key="item"
        :id="item"
        class="remote-stream-container">
      </div>
    </div>
  </div>
</template>

<script>
import rtc from './mixins/rtc.js';
import TRTC from 'trtc-sdk-v5';
import axios from 'axios'

export default {
  name: 'compRoom',
  mixins: [rtc],
  props: {
    sdkAppId: String,
    type: String,
    userId: String,
    roomId: Number,
    loginKey: String,
    cameraId: String,
    microphoneId: String,
  },
  data() {
    return {
      logList: [],
      showCopiedTip: false,
      sdkAppId_raw: String,
    };
  },
  mounted() {
    this.trtc = TRTC.create();
  },
  computed: {
    isHostMode() {
      return true
    },
    isEnLang() {
      return this.$i18n.locale === 'en';
    },
  },
  watch: {
    cameraId(val) {
      this.switchDevice('video', val);
    },
    microphoneId(val) {
      this.switchDevice('audio', val);
    },
  },
  methods: {
    async handleEnterRoom() {
      if (!this.userId || !this.roomId) {
        alert(this.$t('Please enter userId and roomId'));
        return;
      }
      let that = this;
      await axios.post('https://api.loli.ren/v2/meet/getUserSig', {
        username: this.userId,
        room: this.roomId,
        logincode: this.loginKey,
      })
      .then(function (response) {
        console.log(response.data)
        if(response.data.code == 1){
          //this.sdkAppId = response.data.data.appId
          that.userSig = response.data.data.UserSig
        }else{
          alert(response.data.error);
          //alert(that.$t('API Error 01'));
          return;
        }
      }.bind(this))   
      .catch(function (error) {
        console.log(error);
        alert(that.$t('API Error 02'));
        return;
      })

      if(this.sdkAppId == "" || !this.userSig || !this.userId || !this.roomId ){
        console.log("error");
        return
      }
      //await this.enterRoom(this.roomId, this.sdkAppId_raw, this.userId, this.userSig);
      await this.enterRoom();
      this.handleStartLocalAudio();
      this.handleStartLocalVideo();
    },

    async handleExit() {
      await this.exitRoom();
    },

    async handleStartScreenShare() {
      if (!this.sdkAppId ) {
        alert(this.$t('Please enter sdkAppId'));
        return;
      }
      this.shareStatus = 'sharing';
      try {
        await this.trtc.startScreenShare();
        this.shareStatus = 'shared';
        this.addSuccessLog('Start share screen success');
      } catch (error) {
        this.shareStatus = 'stopped';
        this.addFailedLog(`Start share error: ${error.message}`);
      }
    },

    async handleStopScreenShare() {
      if (this.shareStatus !== 'shared') {
        this.addFailedLog('The Share is not started');
        return;
      }
      this.shareStatus = 'stopping';
      try {
        await this.trtc.stopScreenShare();
        this.shareStatus = 'stopped';
        this.addSuccessLog('Stop share screen success');
      } catch (error) {
        this.shareStatus = 'shared';
        this.addFailedLog(`Stop share error: ${error.message}`);
      }
    },

    addSuccessLog(log) {
      this.logList.push({
        type: 'success',
        log,
      });
      const { scrollHeight } = this.$refs.logContainer;
      this.$refs.logContainer.scrollTop = scrollHeight;
    },

    addFailedLog(log) {
      this.logList.push({
        type: 'failed',
        log,
      });
      const { scrollHeight } = this.$refs.logContainer;
      this.$refs.logContainer.scrollTop = scrollHeight;
    },
    reportSuccessEvent(name) {
      const ext3 = name === 'enterRoom' ? this.sdkAppId : 0;
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-success`,
        ext2: this.$DEMOKEY,
        ext3,
      });
    },
    reportFailedEvent(name, error, type = 'rtc') {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-failed#${this.roomId}*${type === 'share' ? this.shareUserId : this.userId}*${error.message}`,
        ext2: this.$DEMOKEY,
        ext3: 0
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.rtc-container {
  .label {
    margin: 14px 0 6px;
    text-align: left;
    font-weight: bold;
  }

  .control-container {
    text-align: left;
    margin-bottom: 10px;
    div:not(:nth-last-child(1)) {
      margin-bottom: 10px;
    }
    .button:not(:first-child) {
      margin-left: 2px;
    }
  }

  .info-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .log-container {
      flex-grow: 1;
      border: 1px solid #dddddd;
      height: 360px;
      padding: 10px;
      margin-right: 16px;
      overflow-y: scroll;
      .log-label {
        margin: 0 0 6px;
        font-weight: bold;
      }
      .log-state {
        display: inline-block;
        margin-right: 6px;
      }
      > div {
        font-size: 12px;
      }
    }
    .local-stream-container {
      width: 480px;
      height: 360px;
      position: relative;
      flex-shrink: 0;
      .local-stream-content {
        width: 100%;
        height: 100%;
      }
      .local-stream-control {
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 10px;
        .control {
          margin-left: 10px;
        }
        .icon-class {
          color: #fff;
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .info-container-mobile {
    display: block;
    .log-container {
      margin-right: 0;
    }
    .local-stream-container {
      width: 320px;
      height: 240px;
      margin-top: 10px;
    }
  }

  .remote-container {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    .remote-stream-container {
      width: 550px;
      height: 340px;
      margin: 0 10px 10px 0;
    }
  }
}
</style>

<i18n>
{
	"en": {
		"Operation": "Operation",
    "Enter Room": "Enter Room",
    "Start Local Audio": "Start Local Audio",
    "Stop Local Audio": "Stop Local Audio",
    "Start Local Video": "Start Local Video",
    "Stop Local Video": "Stop Local Video",
    "Exit Room": "Exit Room",
    "Start Screen Share": "Start Screen Share",
    "Stop Screen Share": "Stop Screen Share",
    "Please enter userId and roomId": "Please enter userId and roomId",
    "API Error 01": "API login error!",
    "API Error 02": "API comm error!"
	},
	"zh-cn": {
		"Operation": "操作",
    "Enter Room": "进入房间",
    "Start Local Audio": "采集麦克风",
    "Stop Local Audio": "终止采集麦克风",
    "Start Local Video": "采集摄像头",
    "Stop Local Video": "终止采集摄像头",
    "Exit Room": "离开房间",
    "Start Screen Share": "开始共享屏幕",
    "Stop Screen Share": "停止共享屏幕",
    "Please enter userId and roomId": "请输入 userId 和 roomId",
    "API Error 01": "API 登录失败！",
    "API Error 02": "API 访问失败！"
	}
}
</i18n>
